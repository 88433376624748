<template>
<div>
  <autogestion-Paso-Uno class="landscape"></autogestion-Paso-Uno>
  <v-row>
    <v-col cols="12" style="height: 100px;"></v-col>
  </v-row>
  <v-container style="margin-top:75px">
      <v-row align="center"> 
        <v-col md="12">
            <v-row>
              <v-col md="8" offset-md="2">
                <h3 class="ml-1 mb-n2 colorCustom2--text">Ingrese DNI</h3>
              </v-col>   
            </v-row>           
          <v-row>
            <v-col md="8" offset-md="2">             
              <v-text-field   
                :value="dataForm.cuenta"   
                class="ml-auto"
                solo
                hide-details
                type="number"
                data-layout="normal"
                autocomplete="off"
                @input="onInputChange"    
                ref="cementerio"
              />     
             </v-col>  
            </v-row>
            <v-row>
              <v-col md="8" offset-md="2">
                <SimpleKeyboard @onChange="onChange" :input="dataForm.cuenta" :layout="$store.getters.getLayoutNumeric" :buttonTheme="$store.getters.getButtonThemeNumeric"/>
              </v-col>
            </v-row>             
            <v-row justify="center">
              <v-col md="8">
                <v-row>
                  <v-col style="text-align:center">
                    <botonVolver />
                  </v-col>
                  <v-col style="text-align:center">
                    <botonBuscar @Click="Buscar" />
                  </v-col>  
                </v-row>
              </v-col>
            </v-row> 
        </v-col>
      </v-row>    
  </v-container>

</div>
</template>
<script>
import SimpleKeyboard from '../components/teclados/KeyBoard.vue' 
import '../components/teclados/dark-theme.css'
  export default {
    components: {
      SimpleKeyboard,
      autogestionPasoUno: () => import('../components/autogestionPasoUno.vue'),
    },
    data () {
      return {
        dataForm: {
          cuenta: '',
        },
      }
    },
    mounted () {
      this.$refs.cementerio.focus()   
    },    
    methods: {
      onChange(input) {
        this.dataForm.cuenta = input;
      },
      onInputChange(input) {
        this.dataForm.cuenta = input;
      },  
      Buscar: async function () {    
        let error = false
        var existe = false     
        await this.$store.dispatch('axiosMod/getData', { url: '/SAT_WS/rest/cuentas/existe/' + this.dataForm.cuenta + '/CEMENTERIO?td=undefined' }).then((res) => { 
          existe = res.existe
          if (!existe) {
            this.$refs.mensajeCerrarDialog.open(this.$store.getters.getMensajes["noCuenta"])      
          }
        }).catch(() => {
          error = true
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
        })    

        if (existe && !error) {                    
          this.$router.push({ name: 'cuotasGeneral', params: { tributo: 'CEMENTERIO', cuenta:this.dataForm.cuenta }})
        }   
      }   
    },
  }
</script>